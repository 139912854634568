import IDBMethod from "./IDBMethod";
import { startIndexedDbActivitiesBeforeUsed } from "@/static/js/compatibility";
import LocalStorage from "@/static/js/LocalData/LocalStorage";

startIndexedDbActivitiesBeforeUsed();

class LocalData {
  static IDBModal = new LocalData({});
  static LSModal = new LocalData({ mod: "localStorage" });
  operationMethods = {
    IDB: new IDBMethod("zijinshe_webapp", "dictionary"),
    localStorage: new LocalStorage()
  };
  operationMethod = {};

  constructor({ mod = "IDB" }) {
    let methodMod = mod;
    if (!("indexedDB" in window) && mod === "IDB") {
      methodMod = "localStorage";
    }
    this.operationMethod = this.operationMethods[methodMod];
  }

  save() {
    return this.operationMethod.save(...arguments);
  }

  get() {
    return this.operationMethod.get(...arguments);
  }

  delete() {
    return this.operationMethod.delete(...arguments);
  }

  clear() {
    return this.operationMethod.clear(...arguments);
  }
}

export const localData = new LocalData({});
export default LocalData;

const pics = [
  "https://img01.yzcdn.cn/upload_files/2022/05/09/FnYwOQ1dtwf5TiuFTHLKjzlDTnd_.png",
  "https://img01.yzcdn.cn/upload_files/2022/05/09/Frr1cdzVOFplvdvRbHus2PH5ezhg.png",
  "https://img01.yzcdn.cn/upload_files/2022/05/09/Fj3AI1QKeGaZsHVfQmONXrYoM_wL.png",
];

export const dataList = {
  yuwen: {
    key: 1,
    pic: pics[0],
    mastered: 0,
    wrong: 0,
    eng: "Chinese",
    color: "#FF8F86",
    dicValue: "2",
    ch: "语文",
  },
  shuxue: {
    key: 2,
    pic: pics[1],
    mastered: 0,
    wrong: 0,
    eng: "Mathematics",
    color: "#4B96FF",
    dicValue: "1",
    ch: "数学",
  },
  yingyu: {
    key: 3,
    pic: pics[2],
    mastered: 0,
    wrong: 0,
    eng: "English",
    color: "#2FDEA3",
    dicValue: "3",
    ch: "英语",
  },
};

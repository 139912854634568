class LocalStorage {
  save(key, value) {
    return window.localStorage.setItem(key, value);
  }
  get(key) {
    return window.localStorage.getItem(key);
  }
  delete(key) {
    return window.localStorage.removeItem(key);
  }
  clear() {
    return window.localStorage.clear();
  }
}

export default LocalStorage;

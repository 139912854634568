/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import request from "@/request";
import { getformData } from "@/static/js/zjs";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace API {
  export interface ATTACH {
    answerPicPath: string;
    blankPicPath: string;
    id: number;
    ltx: number;
    lty: number;
    pageAnswerPicPath: string;
    pageBlankPicPats: string;
    pageId: number;
    pageName: string;
    pageNo: number;
    width: number;
    height: number;
  }
  export type GRADES = number[];
  export interface DATA_LIST_WRONG_BOOK_QUESTION {
    archived: "N" | "Y";
    attaches: ATTACH[];
    bookId: number;
    bookName: string;
    category: number;
    chapterId: number;
    chapterTitle: string;
    course: number;
    extrapolationEnabled: number;
    grades: GRADES;
    id: number;
    key: string;
    preferenceBookName: string;
    questionNo: string;
    sourceType: string;
    wrongBookCreateTime: string;
    wrongBookId: number;
  }
}

export const listCourseQuestionCount = (
  grade: string,
  volume: string,
  appid: string,
  openid: string
): Promise<{
  [key in number]: { archived: "Y" | "N"; count: number; course: number }[];
}> => {
  return request
    .get(
      `/api-internet-user/open/weixin_user_wrongbook/count-by-course/${appid}/${openid}`,
      {
        params: {
          grade,
          volume,
          appid,
          openid,
        },
      }
    )
    .then((res) => {
      return res.data;
    });
};

export const listWrongBookQuestions = (
  course: string,
  grade: string,
  volume: string,
  archived: "Y" | "N",
  appid: string,
  openid: string
): Promise<API.DATA_LIST_WRONG_BOOK_QUESTION[]> => {
  return request
    .get(
      `/api-internet-user/open/weixin_user_wrongbook/list/${appid}/${openid}`,
      {
        params: {
          grade,
          volume,
          course,
          archived,
        },
      }
    )
    .then((res) => {
      return res.data.filter(
        (i: API.DATA_LIST_WRONG_BOOK_QUESTION) => i.sourceType === "smthw"
      );
    });
};

export const genPdfWrongQuestions = (
  ids: number[],
  title: string,
  show: 0 | 1,
  openid: string
): Promise<{ data: string }> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return request({
    url: `/api-internet-user/open/weixin_user_wrongbook/nfzijin/getWrbQuestionPdf`,
    method: "post",
    data: {
      title,
      show,
      ids: ids.join(","),
      openid,
    },
  }).then((res) => res.data);
};

export const changeWrongQuestionArchiveStatus = (
  ids: number[],
  ops: number
): Promise<string> => {
  return request
    .post(
      `/api-internet-user/open/weixin_user_wrongbook/archive-all/${ids.join(
        ","
      )}/${ops}`,
      getformData({
        ids: ids.join(","),
        ops,
      })
    )
    .then((res) => res.data);
};

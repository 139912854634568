
import { defineComponent, PropType } from "vue";
import type { PickerColumn } from "vant";

export default defineComponent({
  name: "VantPopPicker",
  emits: ["confirm"],
  props: {
    columns: {
      type: Array as PropType<PickerColumn[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    showPicker() {
      if (!this.disabled) {
        this.show = true;
      }
    },
    onConfirm(value: any, index: number) {
      this.$emit("confirm", value, index);
      this.show = false;
    },
  },
});


import { defineComponent, Ref, ref } from "vue";
import StudentMenuBar from "../../component/StudentMenuBar/index.vue";
import { dataList } from "./helper";
import VantPopPicker from "@/components/VantPopPicker/index.vue";
import type { PickerColumn } from "vant";
import { initZidian } from "../../common/bookName";
import { listCourseQuestionCount } from "../../API/wrongbook";
import LocalData from "@/static/js/LocalData";

export default defineComponent({
  setup() {
    const refDataList = ref([dataList.yuwen, dataList.shuxue, dataList.yingyu]);
    const grade = ref("");
    const showGradePopup = ref(true);
    const selectGrade = () => {
      showGradePopup.value = true;
    };
    const colums: Ref<PickerColumn[]> = ref([]);

    const grades: Ref<
      {
        key: string;
        value: string;
      }[]
    > = ref([]);

    const setupRet = {
      dataList: refDataList,
      grade,
      grades,
      selectGrade,
      showGradePopup,
      colums,
    };
    return setupRet;
  },
  methods: {
    async initData() {
      const date0901 = new Date(new Date().getFullYear(), 9, 1);
      let lsGradeName = LocalData.LSModal.get("wrongBookGradeName");
      let lsVolumeName = LocalData.LSModal.get("wrongBookVolumeName");

      // 默认的错题册过滤年级
      if (!lsGradeName) {
        await this.$store.dispatch(
          "smartHomeworkUserClient/user/getStudentUserInfo",
          true
        );
        // 请求数据
        lsGradeName =
          this.$store.state.smartHomeworkUserClient.user.studentUserInfo
            .gradeName;
        LocalData.LSModal.save("wrongBookGradeName", lsGradeName);
      }
      if (!lsVolumeName) {
        lsVolumeName = Date.now() < date0901.getTime() ? "下" : "上";
        LocalData.LSModal.save("wrongBookVolumeName", lsVolumeName);
      }

      this.grade = lsGradeName + lsVolumeName;
      await initZidian().then((zidian) => {
        if (zidian && zidian.grade) {
          const grades = zidian.grade.slice(0, 9).map((item) => item.key);
          this.grades = zidian.grade;
          this.colums = [
            {
              values: grades,
              defaultIndex:
                grades.findIndex((item) => item === this.grade.slice(0, 3)) ||
                0,
            },
            {
              values: ["上", "下"],
              defaultIndex: this.grade.slice(3, 4) === "下" ? 1 : 0,
            },
          ];
        }
      });
      // this.dataList[1].mastered = 100;
    },
    courseClicked(item: typeof dataList.yuwen) {
      const { gradeVal, volumnVal } = this.getGradeVolumnVal();
      this.$router.push({
        path: `/smartHomeworkUserClient/wrongQuestionsList/${item.dicValue}/${gradeVal}/${volumnVal}`,
        query: {
          name: `${this.grade}${item.ch}错题册`,
        },
      });
    },
    getGradeVolumnVal() {
      const tGrade = this.grade.slice(0, 3),
        tVolumn = this.grade.slice(3, 4);
      const gradeVal = this.grades.find((item) => item.key === tGrade)?.value;
      const volumnVal = tVolumn === "上" ? "1" : "2";
      return { gradeVal, volumnVal };
    },
    async getListData() {
      const { gradeVal, volumnVal } = this.getGradeVolumnVal();
      if (gradeVal) {
        const { openid, appid } =
          this.$store.state.smartHomeworkUserClient.user.studentUserInfo;
        const data = await listCourseQuestionCount(
          gradeVal,
          volumnVal,
          appid as string,
          openid as string
        );

        this.dataList = this.dataList.map((item) => {
          const _data = data[Number(item.dicValue)];
          item.mastered = 0;
          item.wrong = 0;
          if (!_data) return item;
          item.wrong =
            _data.find(
              (dataItem) =>
                dataItem.archived === "N" &&
                dataItem.course == Number(item.dicValue)
            )?.count || 0;
          item.mastered =
            _data.find(
              (dataItem) =>
                dataItem.archived === "Y" &&
                dataItem.course == Number(item.dicValue)
            )?.count || 0;

          return item;
        });
      }
    },
    gradeConfirm(items: Array<string>) {
      this.grade = items.join("");
      LocalData.LSModal.save("wrongBookGradeName", items[0]);
      LocalData.LSModal.save("wrongBookVolumeName", items[1]);
      this.$store.state.smartHomeworkUserClient.user.wrongQuestionsBookGradeVolumnText =
        this.grade;
      this.getListData();
    },
  },
  mounted() {
    this.initData().then(() => this.getListData());
  },
  components: { StudentMenuBar, VantPopPicker },
});

/**
 * 兼容js处理
 */

// 兼容ios键盘问题
export function iosKeyboard() {
  window.addEventListener("focusin", function () {
    window.focusScroll =
      document.documentElement.scrollTop || document.body.scrollTop;
  });
  window.addEventListener("focusout", function () {
    document.body.scrollTop = document.documentElement.scrollTop =
      window.focusScroll || 0;
    window.scrollTo(window.focusScroll || 0, 0);
  });
}

// 检查浏览器是否支持 IntersectionObserverEntry API
export function checkIntersectionObserver() {
  const inBrowser = typeof window !== "undefined" && window !== null;
  if (
    inBrowser &&
    "IntersectionObserver" in window &&
    "IntersectionObserverEntry" in window &&
    "intersectionRatio" in window.IntersectionObserverEntry.prototype
  ) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!("isIntersecting" in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(
        window.IntersectionObserverEntry.prototype,
        "isIntersecting",
        {
          get: function () {
            return this.intersectionRatio > 0;
          },
        }
      );
    }
    return true;
  }
  return false;
}

// 设置currentTime
/**
 * ios/安卓音频设置音频开始时间兼容处理,ios要play之后才能设置
 * @param {Element} audio 音频dom
 * @param {Number} time 时间
 * @param {Number} timeout 播放超时时间，默认10s
 */
export async function setCurrentTimePromise(audio, time, timeout = 100000) {
  const isIos = /(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent);
  if (!audio) return;
  if (!isIos) {
    audio.currentTime = time;
    return audio.currentTime;
  } else {
    const isCanplay = audio.readyState === 4;
    return new Promise(function (resolve, reject) {
      if (!isCanplay) {
        const IOS_AUDIOCANPLAYLISTENER = function () {
          // 设置播放时间
          audio.currentTime = time;
          audio.removeEventListener("canplay", IOS_AUDIOCANPLAYLISTENER);
          resolve(audio.currentTime);
        };
        audio.addEventListener("canplay", IOS_AUDIOCANPLAYLISTENER);
      } else {
        resolve(audio.currentTime);
        audio.currentTime = time;
      }
      setTimeout(() => {
        audio.currentTime = time;
        reject();
      }, timeout);
    });
  }
}

// IOS 14.6 存在进入页面时indexedDb进程活动被挂起的情况
// 在所有的脚本开始之前，主动启动进程，可以在一定程度上解决（并非完全））
// https://bugs.webkit.org/show_bug.cgi?id=226547
export function startIndexedDbActivitiesBeforeUsed() {
  if (window.indexedDB) {
    // 删除不存在的表
    window.indexedDB.deleteDatabase("dummy-database");
  }
}

import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showPicker && _ctx.showPicker(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createVNode(_component_van_popup, {
      show: _ctx.show,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
      round: "",
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_picker, {
          columns: _ctx.columns,
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false)),
          onConfirm: _ctx.onConfirm
        }, null, 8, ["columns", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}